<template>
  <b-card>
    <b-row>

      <b-col
        v-if="salesNetworkUser.id!=1"
        class="col-12"
      >
        <h3>Rete dell'utente {{ salesNetworkUser.fullName }}</h3>
      </b-col>

      <!-- {{ salesNetworkUser }} -->
      <b-col
        v-for="userRootNode in salesUserRootNodes"
        :key="userRootNode.id"
        :class="salesUserRootNodes.length>1 ? 'col-6' : 'col-8' "
      >
        <b-col
          v-if="userRootNode.parents"
        >
          <b-link
            v-for="(bcParent, index) in userRootNode.parents.filter(u => u.id_user != salesNetworkUser.id)"
            :key="`bc_${bcParent.id_user}`"
            :to="{ name: 'sales-network'}"
          >
            <span
              v-if="index > 0"
            >
              > {{ bcParent.user.fullName }}
            </span>
            <span
              v-if="index == 0"
            >
              <feather-icon
                icon="HomeIcon"
                size="14"
              />
            </span>
          </b-link>
        </b-col>

        <b-col
          class="col-12"
        >
          <table class="table">
            <thead>
              <tr>
                <th class="col-md-2">
                  <span class="font-weight-bold">Name</span>
                </th>
                <th class="col-md-3 pl-3">
                  <span class="font-weight-bold">Role</span>
                </th>
              </tr>
            </thead>
            <tr>
              <td colspan="2">
                <tree-menu
                  v-if="userRootNode"
                  :sales-node="userRootNode"
                  :parent-node="userRootNode"
                  :fields="fields"
                  :agencies="agencies"
                  :depth="0"
                  @onDeleteNetworkChildren="removeSalesNetworkChildren"
                  @onOpenEditNetworkChildren="onOpenEditNetworkChildren"
                />
              </td>
            </tr>
          </table>
        </b-col>

        <b-col
          v-if="currentUser.role=='admin'"
          class="col-12"
        >
          <b-col md="9">
            <!-- Employee -->
            <validation-provider
              #default="validationContext"
              name="Employee"
            >
              <b-form-group
                label="Aggiungi Utente"
                label-for="childUser"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  id="childUser"
                  v-model="addChildrenUser"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="true"
                  :options="employees"
                  label="fullName"
                  index="id"
                  input-id="childUser"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="2">
            <b-button
              variant="primary"
              class="mt-2 mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click="addChildrenToCurrenteSalesNetwork(userRootNode.id)"
            >Aggiungi
            </b-button>
          </b-col>
        </b-col>

      </b-col>
    </b-row>

    <b-modal
      id="update-sales-useres"
      ref="update-sales-useres"
      centered
      hide-footer
      size="lg"
    >
      <b-card
        class="text-center mb-4"
        sub-title="Set Sales Network"
      >
        <b-col
          md="12"
        >
          <b-form-group>
            <v-select
              id="salesUserUpdate"
              v-model="updateParentNode"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="true"
              :options="selectChildrenList"
              label="fullName"
              index="id"
              input-id="salasUserUpdate"
            />

            <!--
            <v-select
              id="salesUserUpdate"
              v-model="updateParentId"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="true"
              :options="selectChildrenList"
              label="fullName"
              index="id"
              :reduce=" x => x.id"
              input-id="salasUserUpdate"
            />
            -->
          </b-form-group>
        </b-col>

        <b-col md="2">
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            @click="updateSalesNetworkParent"
          >Modifica
          </b-button>
        </b-col>
      </b-card>
    </b-modal>

    <b-row
      v-if="salesUserRootNodes.length==0 && currentUser.role=='admin'"
    >
      <b-col md="6">
        <!-- Employee -->
        <validation-provider
          #default="validationContext"
          name="Employee"
        >
          <b-form-group
            label="Nuovo Utente"
            label-for="childUser"
            :state="getValidationState(validationContext)"
          >
            <v-select
              id="childUser"
              v-model="addChildrenUser"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="true"
              :options="employees"
              label="fullName"
              index="id"
              input-id="childUser"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="6">
        <b-button
          variant="primary"
          class="mt-2 mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="addChildrenToCurrenteSalesNetwork(0)"
        >Aggiungi
        </b-button>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BButton, BTable, BDropdownItem, BDropdown, BFormInvalidFeedback, BAvatar, BLink, VBModal,
  // BAlert, BLink, BTableSimple, BFormInput, BThead, BTr, BTh, BTbody,
} from 'bootstrap-vue'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'
import { forEach } from 'postcss-rtl/lib/affected-props'
import formValidation from '@core/comp-functions/forms/form-validation'
import agencyStoreModule from '../Agency/agencyStoreModule'
import userStoreModule from '../../user/userStoreModule'
import folder from './folder.vue'
import TreeMenu from './TreeMenu.vue'
// import Feather from '@/views/ui/feather/Feather.vue'

// import UserEditTabAccount from './UserEditTabAccount.vue'
// import UserEditTabInformation from './UserEditTabInformation.vue'
// import UserEditChangePassword from './UserEditChangePassword.vue'
// import companyStoreModule from '../../dealer/Company/companyStoreModule'
// Notification
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    ValidationProvider,
    BFormInvalidFeedback,
    vSelect,
    TreeMenu,
    // eslint-disable-next-line vue/no-unused-components
    VBModal,
    BLink,
    // BAvatar,
    // BTable,
    // BDropdownItem,
    // BDropdown,
    // BAlert,
    // BLink,
    // BFormInput,
    // ValidationObserver,
    // formValidation,
    // folder,
    // BTableSimple,
    // BThead,
    // BTr,
    // BTh,
    // BTbody,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    salesNetworkUser: {
      type: Object,
      required: true,
      default() { return null },
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'id_user',
          sortable: true,
        },
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'role',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ],
      addChildrenUser: ref({}),
      updateChildrenNode: ref({}),
      updateParentNode: ref({}),
      updateOldParentNode: ref({}),

      // addChildrenId: ref(0),
      // addChildrenUserId: ref(0),
      // updateChildrenId: ref(0),

      updateParentId: ref(0),
      parent: ref({}),
    }
  },
  watch: {
    salesNetworkUser: {
      // the callback will be called immediately after the start of the observation
      handler(val, oldVal) {
        // do your stuff
        // this.fetchSalesNetworkChildren(this.salesNetworkUser)
        this.loadSalesNetwork()
      },
      immediate: true,
    },
  },
  mounted() {
    // this.loadSalesNetwork() // Viene già chiamato nel watch
  },
  setup() {
    // const childrenDealers = ref([])
    const selectChildrenList = ref([])
    // const childrenTree = ref({})
    // const ancestors = ref([])

    const salesUserRootNodes = ref([])

    const employees = ref([])
    const currentUser = getUserData()

    const agencies = ref([])

    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      CompanyID: '',
      country: '',
      contact: '',
    }
    const resetuserData = JSON.parse(JSON.stringify(blankUserData))

    const AGENCY_DEALER_STORE_MODULE_NAME = 'app-agency'
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    // const COMPANY_DEALER_STORE_MODULE_NAME = 'app-company'

    // Register module
    if (!store.hasModule(AGENCY_DEALER_STORE_MODULE_NAME)) store.registerModule(AGENCY_DEALER_STORE_MODULE_NAME, agencyStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    // if (!store.hasModule(COMPANY_DEALER_STORE_MODULE_NAME)) store.registerModule(COMPANY_DEALER_STORE_MODULE_NAME, companyStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AGENCY_DEALER_STORE_MODULE_NAME)) store.unregisterModule(AGENCY_DEALER_STORE_MODULE_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      // if (store.hasModule(COMPANY_DEALER_STORE_MODULE_NAME)) store.unregisterModule(COMPANY_DEALER_STORE_MODULE_NAME)
    })

    const fetchAgencies = async () => {
      await store.dispatch("app-agency/fetchAllDealer").then(res => {
        agencies.value = res.data.agencies
      })
        .catch(error => {
          console.error(error)
          if (error.response.status === 404) {
            // this.childrenDealers.value = undefined
          }
        })
    }

    const navigatePopulateSelect = (node, depth, bc) => {
      // Stampa il nodo corrente con la profondità
      if (depth > 0) {
        // console.log('--->', depth, bc)
        bc = `${bc} ${bc != "" ? ">" : ""} ${node.user.fullName}`
        selectChildrenList.value.push({
          id: node.id,
          fullName: bc,
        })
      }
      // Ottieni tutti i figli del nodo corrente
      const { children } = node

      // Chiamata ricorsiva per ogni figlio
      if (children && children.length > 0) {
        children.forEach((child, pos) => {
          navigatePopulateSelect(child, depth + 1, bc)
        })
      }
    }

    const fetchSalesNetworkChildren = userData => {
      // Recupero l'elenco dei nodi dell'utente e per ognuno di questi carico la rete
      let fetchPath = `app-agency/fetchUserSalesNetwork`
      if ((currentUser.role == "admin" || currentUser.role == "ACRISURE ADMIN") && userData.id == currentUser.id) {
        fetchPath = `app-agency/fetchAllSalesNetwork`
      }
      store.dispatch(fetchPath, userData.id).then(res => {
      // store.dispatch(`app-agency/fetchAllSalesNetwork`, userData.id).then(res => {
        // console.log(res)
        salesUserRootNodes.value = res.data.usersRootNodes

        // Preparo l'array per la select del nodo in caso di modifica
        selectChildrenList.value = []
        if (userData.id == currentUser.id) { // Sto nella scheda rete vendita generale
          salesUserRootNodes.value.forEach((item, pos) => {
            navigatePopulateSelect(item, 0, "")
          })
        } else {
          // Questa è da sistemare nel caso di più padri
          store.dispatch(`app-agency/fetchSalesNetworkTree`, currentUser.id).then(res1 => {
            navigatePopulateSelect(res1.data.childrenTreeDealers, 0, "")
          })
            .catch(error => {
              if (error.response.status === 404) {
                // this.childrenDealers.value = undefined
              }
            })
        }
      })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            // this.childrenDealers.value = undefined
          } else {
            console.error(error)
          }
        })

      /*
      store.dispatch(`app-agency/fetchSalesNetworkChildren`, userData.id).then(res => {
        childrenDealers.value = res.data.childrenDealers
      })
        .catch(error => {
          if (error.response.status === 404) {
            this.childrenDealers.value = undefined
          }
        })
      */
    }

    if (currentUser.role == "admin") {
      store
        .dispatch('app-agency/fetchAllSalesNetworkUsers', currentUser.id)
        .then(response => {
          const { salesUsers, total } = response.data
          employees.value = salesUsers
        })
        .catch(err => {
          console.error(err)
          employees.value = []
        })
    } else {
      // Se non sono admin recupero solo gli utenti collegati alla mia utenze
      store
        .dispatch('app-agency/fetchChildrenSalesNetworkUsers', currentUser.id)
        .then(response => {
          const { salesUsers, total } = response.data
          employees.value = salesUsers
        })
        .catch(err => {
          console.error(err)
          employees.value = []
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      getValidationState,
      fetchSalesNetworkChildren,
      fetchAgencies,
      currentUser,
      salesUserRootNodes,
      selectChildrenList,
      employees,
      agencies,
    }
  },
  methods: {
    async loadSalesNetwork() {
      await this.fetchAgencies()
      this.fetchSalesNetworkChildren(this.salesNetworkUser)
      /*
      store.dispatch("app-agency/fetchAllDealer").then(res => {
        this.agencies = res.data.agencies
        this.fetchSalesNetworkChildren(this.salesNetworkUser)
      })
        .catch(error => {
          console.error(error)
          if (error.response.status === 404) {
            // this.childrenDealers.value = undefined
          }
        })
      */
    },
    getBreadcrumb(parents) {
      let res = ""
      parents.forEach((item, pos) => {
        // if (pos > 0) {
        if (res != "") {
          res = `${res} > ${item.user.fullName}`
        } else {
          res = `${item.user.fullName}`
        }
        // }
      })
      return res
    },
    onOpenEditNetworkChildren(updateChildren, updateOldParentNode) {
      this.updateOldParentNode = updateOldParentNode
      this.updateChildrenNode = updateChildren
      this.$bvModal.show(`update-sales-useres`)
    },
    updateSalesNetworkParent() { // Modifica la posizione di un utente qualunque all'interno della rete
      this.updateSalesNetworkChildren(this.updateParentNode, this.updateOldParentNode, this.updateChildrenNode)
      this.$bvModal.hide(`update-sales-useres`)
    },
    /*
    updateCurrentSalesNetworkParent() { // Modifica il parent dell'utenza corrente
      this.updateSalesNetworkChildren(this.parent.user, this.salesNetworkUser.id)
      this.$bvModal.hide(`update-sales-useres`)
    }, */
    updateSalesNetworkChildren(newParentNode, oldParentNode, childrenNode) {
      console.log('======= UPDATE ========')
      console.log(newParentNode.id)
      console.log(oldParentNode.id)
      console.log(childrenNode.id)
      console.log('=======================')

      store.dispatch(`app-agency/updateSalesNetworkItem`, {
        childrenNodId: childrenNode.id,
        parentNodeid: newParentNode.id,
      })
        .then(response => {
          // this.$router.go(this.$router.currentRoute)
          if (response.data.code < 0) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `An error occurred while saving the changes:  ${response.data.msg.message}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
          if (childrenNode.user && childrenNode.user.roles && childrenNode.user.roles.name.toLowerCase() == "dealer admin") {
            console.log("Modifica anche tutti i venditori appartenenti allo stesso dealer")
            store.dispatch('app-agency/updateSalesAgencyParent', {
              agencyId: childrenNode.user.DealerID,
              newNodeId: newParentNode.id,
              oldNodeId: oldParentNode.id,
            })
              .then(res => {
                this.loadSalesNetwork()
              }).catch(error => {
                this.loadSalesNetwork()
              })
          } else {
            this.loadSalesNetwork()
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `An exception occurred while saving the changes ${err}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          this.$bvModal.hide(`update-sales-useres`)
        })
    },
    addChildrenToCurrenteSalesNetwork(parentNodeid) { // Aggiunge alla rete dell'utente corrente
      // Il padre è l'utente corrente
      // Il figlio è l'utente selezionato
      // this.$bvModal.hide(`add-sales-useresNetworkParent`)
      this.addSalesNetworkChildren(parentNodeid, this.addChildrenUser, this.currentUser.id)
    },
    addSalesNetworkChildren(parentNodeid, childrenUser, parentUser) {
      console.log('====== ADD =======')
      console.log(parentNodeid)
      console.log(childrenUser.id)
      console.log(parentUser.id)
      console.log('==================')

      store.dispatch(`app-agency/addSalesNetworkItem`, {
        // parentNodeid: parentId, //Questo deve dinvetare l'id del nodo padre
        parentNodeid,
        childrenUserId: childrenUser.id,
        parentIdUser: parentUser.id,
      })
        .then(response => {
          // this.$router.go(this.$router.currentRoute)
          // this.loadSalesNetwork()
          if (response.data.code < 0) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `An error occurred while saving the changes:  ${response.data.msg.message}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }

          /* stessa gestione dell'aggiunta dei venditori nel caso di aggiunta dealer venditore. Si chiama la stessa funzionae con oldNode=-1 così si forza la creazione */
          if (childrenUser && childrenUser.roleName.toLowerCase() == "dealer admin") {
            console.log("Modifica anche tutti i venditori appartenenti allo stesso dealer")
            store.dispatch('app-agency/updateSalesAgencyParent', {
              agencyId: childrenUser.DealerID,
              newNodeId: parentNodeid,
              oldNodeId: -1,
            })
              .then(res => {
                this.loadSalesNetwork()
              }).catch(error => {
                this.loadSalesNetwork()
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `An error occurred while saving the changes ${error}`,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          } else {
            this.loadSalesNetwork()
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `An exception occurred while saving the changes ${err}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          this.$bvModal.hide(`update-sales-useres`)
        })
    },
    removeSalesNetworkChildren(deleteChildNode, parentNode) {
      // alert(deleteChildrenId)
      store.dispatch(`app-agency/deleteSalesNetworkItem`, {
        userIdChildren: deleteChildNode.id,
        userIdParent: this.salesNetworkUser.id,
      })
        .then(response => {
          // this.$router.go(this.$router.currentRoute)

          if (deleteChildNode.user && deleteChildNode.user.roles && deleteChildNode.user.roles.name.toLowerCase() == "dealer admin") {
            console.log("Modifica anche tutti i venditori appartenenti allo stesso dealer")
            store.dispatch('app-agency/removeSalesAgencyParent', {
              agencyId: deleteChildNode.user.DealerID,
              parentNodeId: parentNode.id,
            })
              .then(res => {
                this.loadSalesNetwork()
              }).catch(error => {
                this.loadSalesNetwork()
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `An error occurred while saving the changes ${error}`,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          } else {
            this.loadSalesNetwork()
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred while saving the changes',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style>

</style>
