<template>

  <ul
    v-if="saleschild.children && saleschild.children.length > 0"
    class="tree-list"
  >
    <li
      v-for="child in saleschild.children"
      :key="child.id_user"
    >
      <!-- {{ typeof saleschild }} - --> {{ child.id_user }} - {{ child.user.fullName }} - {{ child.user.roles.name }}

      <b-dropdown
        variant="link"
        no-caret
        :right="$store.state.appConfig.isRTL"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>

        <b-dropdown-item
          v-if="$can('write', 'Utenti')"
          :to="{ name: 'japps-users-edit', params: { id: child.id_user } }"
        >
          <feather-icon icon="EditIcon" />
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="$can('delete', 'Utenti')"
          @click="onRemoveChild(child.id_user)"
        >
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">Delete</span>
        </b-dropdown-item>
      </b-dropdown>
      <folder
        v-for="child in saleschild.children"
        :key="`folder_${child.id_user}`"
        :saleschild="child"
        :fields="fields"
      />
    </li>
  </ul>

</template>
<script>
import {
  BDropdown, BDropdownItem,
  // BTr, BTh, BTbody, BTable,
} from 'bootstrap-vue'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-self-import
// import folder from './folder.vue'

export default {
  name: 'Folder',
  components: {
    BDropdown,
    BDropdownItem,
    // BTable,
    // BTr,
    // BTh,
    // BTbody,
  },
  props: {
    saleschild: {
      type: Object,
      required: true,
      default() { return null },
    },
    /*
    saleschild: {
      type: Array,
      default: () => [],
    },
    */
    fields: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup() {
  },
  methods: {
    onRemoveChild(idChild) {
      this.$emit('removeSalesNetworkChildren', idChild)
    },
  }
}
</script>
